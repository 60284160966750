import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { ClientEventOneBaseDataDto } from '../../../../../shared/models/dto/ClientEventOneBaseDataDto';
import { UserWithBoxOfficeDto } from '../../../../../shared/models/dto/UserWithBoxOfficeDto';
import { Page } from '../../../../../shared/models/Page';
import { EventService } from '../../../../../shared/service/backend/event.service';
import { HardLockService } from '../../../../../shared/service/backend/hard-lock.service';
import { MainLoaderService } from '../../../../../shared/service/rx/main-loader.service';
import { UserDetailsService } from '../../../../../shared/service/user-details-service.service';
import { BookingService } from '../../../../../shared/service/backend/booking.service';
import { Ticket } from '../../../../../shared/models/Ticket';
import { ReportsService } from 'src/shared/service/backend/reports.service';

@Component({
  selector: 'app-event-lock-list',
  templateUrl: './event-lock-list.component.html',
  styleUrls: ['./event-lock-list.component.css'],
})
export class EventLockListComponent implements OnInit {
  currentEvent: ClientEventOneBaseDataDto = new ClientEventOneBaseDataDto();
  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();
  lang: string;
  bins: Page<any>;
  eventId: string;
  public page: number = 1;
  public size: number = 6;
  public total: number | null = null;
  public isPrintModalVisible: boolean = false;
  public tickets: Ticket[];
  public currentOrderId: string;
  deletionConfirmModal?: NzModalRef;

  constructor(
    private _hardLockService: HardLockService,
    private activatedRoute: ActivatedRoute,
    private _load: MainLoaderService,
    private _userDetailsService: UserDetailsService,
    private _translateService: TranslateService,
    private _eventService: EventService,
    private modal: NzModalService,
    private bookingService: BookingService,
    private reportService: ReportsService,
  ) {}

  ngOnInit(): void {
    this.user = this._userDetailsService.getUser();
    this._userDetailsService.user$.subscribe(
      next => {
        this.user = next;
      },
      error => {
        console.error(error);
      },
    );

    this.lang = this._translateService.currentLang;
    this._translateService.onLangChange.subscribe(next => {
      this.lang = next.lang;
    });

    this.activatedRoute.params.pipe(take(1)).subscribe(params => {
      this.eventId = params.id;
      this.load(0);
    });

    this._eventService
      .findOnePublicEventParseClientEventOneBaseDataDto(+this.eventId)
      .pipe(take(1))
      .subscribe(event => (this.currentEvent = event));
  }

  printKg(binId: string) {
    this.bookingService.createKg7ReportInvitationBlock(+binId, +this.eventId, this.user.id).subscribe();
  }

  load(page: number) {
    this._hardLockService
      .findAllByEvent(this.eventId, page, this.size)
      .pipe(take(1))
      .subscribe(next => {
        this.bins = next;
        this.total = next.totalElements;
        this._load.endLoader();
      });
  }

  showDeletionConfirm(id): void {
    this.deletionConfirmModal = this.modal.confirm({
      nzTitle: 'Ви впевнені що хочете видалити бронь?',
      nzOnOk: () => {
        this._load.startLoader();
        this._hardLockService.deleteBin(id).subscribe(() => {
          this.bins.content = this.bins.content.filter(v => v.binId !== id);
          this.page = 1;
          this.load(0);
          this._load.endLoader();
        });
      },
    });
  }

  public onPaginationChange(data): void {
    this.page = data.pageIndex;
    this.load(data.pageIndex - 1);
  }

  public onOpenPrintDialog(orderId: string): void {
    this.bookingService.findByBinId(orderId).subscribe(bin => {
      this.tickets = bin?.tickets ?? [];
      this.currentOrderId = orderId;
      this.isPrintModalVisible = true;
    });
  }
}
