import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBin } from 'src/app/client/client/events/event-action/event-action.component';

export type BinType = 'HARD_LOCK' | 'VENDOR' | 'SITE' | 'WIDGET' | 'BOX_OFFICE' | 'INVITE';
export type PaymentType = 'CASH' | 'CARD' | 'NONE' | 'NO_CASH' | 'VENDOR';
export interface finishBinRequestPayload {
  typeOfPayment: PaymentType;
  comment: string;
  order: string;
  promoCode: string;
  useBonuse: boolean;
  user: {
    firstName: string;
    lastName: string;
    mail: string;
    phone: string;
  };
}
@Injectable({
  providedIn: 'root',
})
export class UniversalBinService {
  constructor(private http: HttpClient) {}

  createBin(binType: BinType, eventId: number, userId?: number, unlockSeconds?: number): Observable<string> {
    // const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.post(
      `/v2/create-bin?binType=${binType}&eventId=${eventId}&isKasa=true${userId ? '&userId=' + userId : ''}${
        unlockSeconds ? '&unlockSeconds=' + unlockSeconds : ''
      }`,
      {},
      { responseType: 'text' },
    );
  }
  finishBin(binType: BinType, binId: number, finishBinAditionalInfo?: finishBinRequestPayload, blockRecipient?: string): Observable<any> {
    return this.http.post<any>(
      `/v2/finish-bin-updates?binType=${binType}&binId=${binId}${blockRecipient ? '&comment=' + blockRecipient : ''}`,
      finishBinAditionalInfo,
    );
  }

  lockTicket(binType: BinType, binId: number, eventId: number, ticketsIds: number[]): Observable<void> {
    return this.http.post<void>(`/v2/lock-ticket?binType=${binType}`, { binId, eventId, ticketId: ticketsIds });
  }

  unlockTicket(binType: BinType, binId: number, eventId: number, ticketsIds: number[]): Observable<void> {
    return this.http.post<void>(`/v2/unlock-ticket?binType=${binType}`, { binId, eventId, ticketId: ticketsIds });
  }

  getBin(binType: BinType, binId: string, promocode: string): Observable<IBin> {
    return this.http.get<IBin>(`/v2/find-bin-by-id/${binId}?binType=${binType}${promocode ? '&promocode=' + promocode : ''}`);
  }
}
