import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { WebSocketService } from '../../../../../shared/service/web-socket.service';
import { MainLoaderService } from '../../../../../shared/service/rx/main-loader.service';
import { Config } from '../../../../../shared/config/config';
import { UserWithBoxOfficeDto } from '../../../../../shared/models/dto/UserWithBoxOfficeDto';
import { UserDetailsService } from '../../../../../shared/service/user-details-service.service';
import { User } from '../../../../../shared/models/User';
import { Page } from '../../../../../shared/models/Page';
import { IEventDiscount, EventService } from '../../../../../shared/service/backend/event.service';
import { VendorsService } from 'src/shared/service/backend/vendors.service';
import { HallService } from 'src/shared/service/backend/hall.service';
import { IDictionary } from 'src/shared/interfaces/interfaces';
import { IEvent } from 'src/shared/interfaces/event';
import { ColorPriceService } from 'src/shared/service/backend/color-price.service';
import { BinType, UniversalBinService } from 'src/shared/service/backend/universal-bin.service';
import { ValidatorService } from 'src/shared/service/backend/validator.service';
import { BookingService } from 'src/shared/service/backend/booking.service';
import { ClientEventOneBaseDataDto } from 'src/shared/models/dto/ClientEventOneBaseDataDto';

export type SelectTicketsType = 'fanzone' | 'noPartHall';
export type BinStatusType = 'IN_PROGRESS_LOCKING_BY_VENDOR' | 'BOUGHT' | 'LOCKED_BY_VENDOR' | 'CANCELLED_BY_BOX_OFFICE' | 'BOOKED';
export interface IBin {
  binStatus: BinStatusType;
  bookedWithPay: boolean;
  comment: null;
  commissionSum: number;
  created: string;
  discountSum: number;
  event: IEvent;
  id: number;
  issuedTicketCount: null;
  issuedTicketSum: null;
  lastModified: string;
  nominalSum: number;
  order: string;
  price: number;
  printed: null;
  revertedTicketCount: null;
  revertedTicketSum: null;
  ticketCount: null;
  ticketList: IBinTicket[];
  ticketSum: null;
  timeUnlock: string;
  typeOfPayment: null;
}

export interface IBinTicket {
  abonementEvents: ClientEventOneBaseDataDto[];
  barcode: string;
  bonus: null;
  colorPrice: IColorPrice;
  commissionBooked: null;
  commissionBookedInMoney: null;
  commissionSum: number;
  discount: number;
  discountSum: number;
  discountInMoney: null;
  fanzoneTicketCount: null;
  finalPrice: number;
  id: number;
  price: number;
  printA4: null;
  printTermo: null;
  promoCode: null;
  row: string;
  seat: ITicketSeat;
  seatPosition: string;
  sector: string;
  ticketGroupWithOutSachem: null;
}
export interface IOnSelectTicketEvent {
  fanzoneKey?: string;
  type?: SelectTicketsType;
  ticketGroupId?: number;
  ticketsIds?: number[];
}
export interface IPartHall {
  id: number;
  pattern: string;
  routingName: null; // null
  type: 'MAIN_PART' | 'CHILD_PART' | 'SOLID_PART';
  multiHall?: boolean;
}
export interface IHall {
  id: number;
  name: IDictionary;
  shortName: IDictionary;
}
export interface IColorPrice {
  available: boolean;
  bonusGet: number;
  bonusSell: number;
  color: string;
  id: number;
  price: number;
  freeTickets: number;
}
export interface ITicketColorPrice {
  available: boolean;
  color: string;
  id: number;
  price: number;
}

export interface ITicketSeat {
  fanzone: boolean;
  id: number;
  key: string;
}

export interface ITicketGrop {
  available: boolean;
  canBuy: boolean;
  colorPrice: IColorPrice;
  comment: IDictionary;
  id: number;
  name: IDictionary;
  order: number;
  ticketsForColorPriceCount: number;
}
export interface IFreeTicket {
  colorPrice: ITicketColorPrice;
  id: number;
  seat: ITicketSeat;
  ticketGroupWithOutSachem: ITicketGrop; // null
}

export interface IWebsoketTicket {
  colorPrice: number;
  eventId: number;
  fanzone: boolean;
  order: string;
  seatId: number;
  seatKey: string;
  ticketId: number;
}

export interface IVendor {
  boxOfficeId: number;
  fullName: string;
  id: number;
  phone: string;
}

export type EventActionType = 'block' | 'invitations' | 'order';
export const binActionTypes: { [key in EventActionType]: BinType } = {
  block: 'HARD_LOCK',
  invitations: 'INVITE',
  order: 'VENDOR',
};
@Component({
  selector: 'app-event-action',
  templateUrl: './event-action.component.html',
  styleUrls: ['./event-action.component.css'],
})
export class EventActionComponent implements OnInit, OnDestroy {
  readonly SELECTED_COLOR: string = Config.MY_SELECTED_SEAT;

  socketSubscriptions: Subscription[] = [];
  subscriptions: Subscription[] = [];

  eventId: number;
  orderId: string;
  selectType: 'seat' | 'row' | 'sector' = 'seat';
  hallAction: EventActionType;
  lang: string;

  currentEvent: IEvent;
  partHall: IPartHall;
  colorPrices: IColorPrice[] = [];
  ticketGroups: ITicketGrop[] = [];
  tickets: IFreeTicket[] = [];
  bin: IBin;

  eventDiscounts: (IEventDiscount & { exludedPrices: number[] })[] = [];
  isSameEventDiscountExcludes: boolean = false;

  vendorId: number;
  isVendorModalVisible: boolean = false;
  isVendorContentLoading: boolean = false;
  vendorList: IVendor[];
  selectedVendor: IVendor;
  selectVendorForm: FormControl = new FormControl();

  user: UserWithBoxOfficeDto;
  invitedUser: User;
  invitedUsers: Page<User>;
  tempUser: User = new User();
  checkValidData: boolean = true;
  invitationComment: string = '';
  showPrice: boolean = false;

  promocode: string = '';
  isActivePromoCode = false;

  isChangeStatusMode = false;
  secondBin: IBin;
  secondOrderId: string;
  isPrintModalVisible: boolean;

  blockRecipient = '';

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private webSocket: WebSocketService,
    private loaderService: MainLoaderService,
    private userDetailsService: UserDetailsService,
    private translateService: TranslateService,
    private eventService: EventService,
    private vendorService: VendorsService,
    private hallService: HallService,
    private colorPriceService: ColorPriceService,
    private validatorService: ValidatorService,
    private binService: UniversalBinService,
    private bookingService: BookingService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.activateRoute.url.subscribe(() => {
        {
          const snapshot = this.activateRoute.snapshot;
          if (this.hallAction !== snapshot.url[0].path || this.eventId !== snapshot.params?.id) {
            if (this.eventId) {
              this.destroySockets();
            }

            this.eventId = snapshot.params?.id;
            this.hallAction = snapshot.url[0].path as EventActionType;
            this.orderId = snapshot.queryParams.bin;
            this.secondOrderId = snapshot.queryParams.secondBin;
            if (snapshot.queryParams.vendor) {
              this.vendorId = +snapshot.queryParams.vendor;
            }

            if (this.hallAction === 'order') {
              this.getVendorList();
              if (!this.vendorId) {
                this.isVendorModalVisible = true;
              }
            }

            this.getHall();
          } else {
            if (snapshot.queryParams.vendor && +snapshot.queryParams.vendor !== this.vendorId) {
              this.vendorId = +snapshot.queryParams.vendor;
              this.selectVendorForm.setValue(this.vendorId);
            }

            if (snapshot.queryParams.bin && (!this.bin || this.bin.id !== snapshot.queryParams.bin)) {
              this.loadBin();
            }
          }
          if (snapshot.queryParams.vendor && snapshot.queryParams.bin) {
            this.verifyBinVendor(+snapshot.queryParams.vendor, snapshot.queryParams.bin);
          }
        }
      }),
      this.translateService.onLangChange.subscribe(next => (this.lang = next.lang)),
    );

    this.user = this.userDetailsService.getUser();
    this.lang = this.translateService.currentLang;
  }

  navigateAwaySecondBin(navigateType: 'block' | 'invitations') {
    if (this.secondBin.binStatus === 'BOOKED' && this.secondBin.ticketList.length) {
      this.loaderService.startLoader();
      this.binService
        .unlockTicket(
          binActionTypes.invitations,
          +this.secondOrderId,
          this.eventId,
          this.secondBin.ticketList.map(el => el.id),
        )
        .subscribe(() => {
          if (this.bin.binStatus === 'BOOKED') {
            this.binService
              .unlockTicket(
                binActionTypes.block,
                +this.orderId,
                this.eventId,
                this.bin.ticketList.map(el => el.id),
              )
              .subscribe(() => {
                this.loaderService.endLoader();
                window.location.href = `${window.location.origin}/#/${navigateType}/${this.eventId}/list`;
              });
          } else {
            this.binService
              .lockTicket(
                binActionTypes.block,
                +this.orderId,
                this.eventId,
                this.secondBin.ticketList.map(el => el.id),
              )
              .subscribe(() => {
                this.loaderService.endLoader();
                window.location.href = `${window.location.origin}/#/${navigateType}/${this.eventId}/list`;
              });
          }
        });
    } else if (this.bin.binStatus === 'BOOKED' && this.bin.ticketList.length) {
      this.loaderService.startLoader();
      this.binService
        .unlockTicket(
          binActionTypes[this.hallAction],
          +this.orderId,
          this.eventId,
          this.bin.ticketList.map(el => el.id),
        )
        .subscribe(() => {
          this.router.navigateByUrl(this.hallAction === 'order' ? 'agents' : this.hallAction + '/' + this.eventId + '/list');
          this.loaderService.endLoader();
        });
    } else {
      this.router.navigateByUrl(this.hallAction === 'order' ? 'agents' : this.hallAction + '/' + this.eventId + '/list');
    }
  }

  navigateSecondBin() {
    window.location.href = window.location.href
      .split('&')
      .filter(el => !el.includes('secondBin'))
      .join('&');
    window.location.reload();
    // window.open(`${window.location.origin}/#/invitations/${this.eventId}/list`);
  }

  removeTicketFromSecondBin(ticket: IBinTicket) {
    this.binService.unlockTicket(binActionTypes.invitations, +this.secondOrderId, this.eventId, [ticket.id]).subscribe(() => {
      this.loadSecondBin();
      this.binService.lockTicket(binActionTypes.block, +this.orderId, this.eventId, [ticket.id]).subscribe(() => {
        this.loadBin();
      });
    });
  }

  saveSecondBin() {
    this.binService
      .finishBin(binActionTypes.invitations, +this.secondOrderId, {
        typeOfPayment: 'NONE',
        comment: this.invitationComment,
        order: this.secondOrderId,
        promoCode: null,
        useBonuse: false,
        user: {
          firstName: this.tempUser.firstName,
          lastName: this.tempUser.lastName,
          mail: this.tempUser.mail,
          phone: '38 (000)-00-00-000',
        },
      })
      .subscribe(() => (this.isPrintModalVisible = true));
  }

  setSecondBinInfo(binId: string, vendorId: number) {
    let binsData = JSON.parse(localStorage.getItem('secondBinsData')) || [];
    let newBin = { vendorId, binId, lifeTime: '' };
    if (binsData.find(el => el.binId === binId)) {
      binsData = binsData.map(el => (el.binId === binId ? newBin : el));
    } else {
      binsData.push(newBin);
    }
    localStorage.setItem('secondBinsData', JSON.stringify(binsData));
  }

  selectSeatsSecondBin(event: IOnSelectTicketEvent) {
    this.createSecondBin().subscribe(res => {
      this.secondOrderId = res;
      let isLocketTiket = false;
      if (event.ticketsIds) {
        isLocketTiket = this.bin ? !!this.bin.ticketList.find(el => el.id === event.ticketsIds[0]) : false;
      }

      if (event.fanzoneKey) {
        event.ticketsIds = this.bin.ticketList.filter(el => el.seat.key === event.fanzoneKey).map(el => el.id);
      } else if (event.ticketGroupId) {
        event.ticketsIds = [this.tickets.filter(el => el.ticketGroupWithOutSachem.id === event.ticketGroupId)[0].id];
      }
      const secondLockTicket = this.secondBin ? !!this.secondBin.ticketList.find(el => el.id === event.ticketsIds[0]) : false;
      if (!isLocketTiket && !secondLockTicket) {
        return;
      }
      event.ticketsIds = event.ticketsIds.filter(el =>
        (isLocketTiket ? this.bin.ticketList : this.secondBin.ticketList).map(ele => ele.id).includes(el),
      );

      this.loaderService.startLoader();
      if (secondLockTicket) {
        this.binService.unlockTicket(binActionTypes.invitations, +res, this.eventId, event.ticketsIds).subscribe(() => {
          this.loadSecondBin();
          this.binService.lockTicket(binActionTypes.block, +this.orderId, this.eventId, event.ticketsIds).subscribe(() => {
            this.loadBin();
          });
        });
      } else {
        this.binService.unlockTicket(binActionTypes.block, +this.orderId, this.eventId, event.ticketsIds).subscribe(() => {
          this.loadBin();
          this.binService.lockTicket(binActionTypes.invitations, +res, this.eventId, event.ticketsIds).subscribe(() => {
            if (!this.activateRoute.snapshot.queryParams.secondBin) {
              this.router
                .navigate([this.router.url.split('?')[0]], {
                  queryParams: { secondBin: this.secondOrderId },
                  queryParamsHandling: 'merge',
                })
                .then(() => {
                  this.loadSecondBin();
                });
            } else {
              this.loadSecondBin();
            }
          });
        });
      }

      this.setBinInfo(this.orderId, this.vendorId);
    });
  }

  loadSecondBin(isFirstTime?: boolean): void {
    if (!this.secondOrderId) return;
    this.binService.getBin(binActionTypes.invitations, this.secondOrderId, '').subscribe(bin => {
      if (bin.ticketList && !!this.partHall && isFirstTime) {
        bin.ticketList.forEach(ticket => {
          document.getElementById(ticket.seat.key).style.fill = 'red';
          document.getElementById(ticket.seat.key).setAttribute('seatId', String(ticket.seat.id));
          document.getElementById(ticket.seat.key).setAttribute('ticketId', String(ticket.id));
        });
      }
      this.secondBin = bin;
      // this.tickets = this.tickets.filter(ticket => !this.bin.ticketList.map(el => el.id).includes(ticket.id));
    });
    this.loaderService.endLoader();
  }

  displayCurrentSector(id: string) {
    document.getElementById('general').style.display = id === 'general' ? 'block' : 'none';
    Array.from(document.querySelectorAll('[sector_name]')).forEach(el => {
      (el as HTMLElement).style.display = el.id === id ? 'block' : 'none';
    });
  }

  updateBinVendor(vendorId: number, binId: string, currentVendorId: number) {
    this.vendorService.updateBin(binId, vendorId, currentVendorId).subscribe(res => this.setBinInfo(binId, vendorId));
  }

  verifyBinVendor(vendorId: number, binId: string) {
    const binsData = JSON.parse(localStorage.getItem('binsData')) || [];
    const currentBin = binsData.find(el => el.binId === binId);
    if (currentBin && currentBin.vendorId !== vendorId) {
      this.updateBinVendor(vendorId, binId, currentBin.vendorId);
    }
  }

  setBinInfo(binId: string, vendorId: number) {
    let binsData = JSON.parse(localStorage.getItem('binsData')) || [];
    let newBin = { vendorId, binId, lifeTime: '' };
    if (binsData.find(el => el.binId === binId)) {
      binsData = binsData.map(el => (el.binId === binId ? newBin : el));
    } else {
      binsData.push(newBin);
    }
    localStorage.setItem('binsData', JSON.stringify(binsData));
  }

  getHall(): void {
    combineLatest([this.eventService.getEventDataById(this.eventId), this.hallService.findOneHallByEvent(this.eventId)]).subscribe(
      ([event, hall]: [IEvent, IHall]) => {
        this.currentEvent = event;
        combineLatest([this.getPartHallOrTicketGroups(hall), this.getDiscounts(), this.getColorPrices(), this.getFreeTickets()]).subscribe(
          res => {
            // this.makeSectionsGrey(this.tickets);
            this.initSockets();
            this.loadBin(true);
            this.loadSecondBin(true);

            this.loaderService.endLoader();
          },
        );
      },
    );
  }

  getPartHallOrTicketGroups(hall: IHall): Observable<ITicketGrop[] | IPartHall> {
    if (hall) {
      this.partHall = { ...this.partHall, id: hall.id };
      return this.hallService.findFirstPartHall(hall.id).pipe(tap((partHall: IPartHall) => (this.partHall = partHall)));
    } else {
      return this.eventService.getTicketGroups(this.eventId).pipe(tap((res: ITicketGrop[]) => (this.ticketGroups = res)));
    }
  }

  getDiscounts(): Observable<IEventDiscount[]> {
    return this.hallAction === 'block'
      ? of(null)
      : this.eventService.getEventDiscounts(this.eventId).pipe(
          tap(res => {
            this.isSameEventDiscountExcludes = res.every(el => el.excludedColorPrices === res[0].excludedColorPrices);
            this.eventDiscounts = res.map(el => {
              return {
                ...el,
                exludedPrices: el.excludedColorPrices
                  .split(',')
                  .map(id =>
                    el.colorPriceList.find(colorPrice => colorPrice.id === +id)
                      ? el.colorPriceList.find(colorPrice => colorPrice.id === +id).price
                      : null,
                  )
                  .filter(ele => ele),
              };
            });
          }),
        );
  }

  getColorPrices(): Observable<IColorPrice[]> {
    return this.colorPriceService.getColorPrices(this.eventId).pipe(tap(res => (this.colorPrices = res)));
  }

  getFreeTickets(): Observable<IFreeTicket[]> {
    return this.eventService.findFreeTicketsByEventId(this.eventId).pipe(
      tap(res => {
        this.tickets = res;
      }),
    );
  }

  createBin(): Observable<string> {
    if (this.orderId) {
      return of(this.orderId);
    }
    return this.binService.createBin(
      binActionTypes[this.hallAction],
      this.eventId,
      this.hallAction === 'order' ? this.vendorId : this.hallAction === 'invitations' ? this.user.id : null,
    );
  }
  createSecondBin(): Observable<string> {
    if (this.secondOrderId) {
      return of(this.secondOrderId);
    }
    return this.binService.createBin(binActionTypes.invitations, this.eventId, this.user.id);
  }

  initSockets(): void {
    this.webSocket.initializeWebSocketConnection(this.eventId);
    this.socketSubscriptions.push(
      this.webSocket.lockWebSocketData$.subscribe((next: IWebsoketTicket) => {
        if (this.partHall && !next.fanzone) {
          const seatElement = document.querySelectorAll(`[seatId="${next.seatId}"]`)[0] as HTMLElement;
          seatElement.style.fill =
            Config[
              +next.order === +this.orderId
                ? 'MY_SELECTED_SEAT'
                : +next.order === +this.secondOrderId
                ? 'MY_SELECTED_SEAT_RED'
                : 'DISABLE_SEAT'
            ];

          seatElement.style.pointerEvents = +this.orderId === +next.order || +next.order === +this.secondOrderId ? 'all' : 'none';
        }
        this.tickets = this.tickets.filter(el => el.id !== next.ticketId);
      }),
      this.webSocket.unLockWebSocketData$.subscribe(next => {
        if (this.partHall && !next.fanzone) {
          const seatElement = document.getElementById(next.seatKey);
          seatElement.style.fill = this.colorPrices.find(el => el.id === next.colorPrice).color;
          seatElement.style.pointerEvents = 'all';
        }
        // if (next.fanzone) {  ///FANZONE
        //     const el = document.getElementById(next.seatKey);
        //     const count = +el.getAttribute('tickets_count');
        //     el.setAttribute('tickets_count', 1 + count + '');
        //   }
        this.tickets.push({
          colorPrice: this.colorPrices.find(el => el.id === next.colorPrice),
          id: next.ticketId,
          seat: { fanzone: next.fanzone, id: next.seatId, key: next.seatKey },
          ticketGroupWithOutSachem:
            next.seatKey === 'WITHOUT_SCHEME' ? this.ticketGroups.find(el => el.colorPrice.id === next.colorPrice) : null, // null
        });
        this.loaderService.endLoader();
      }),
    );
  }

  loadBin(isFirstTime?: boolean): void {
    if (!this.orderId) return;
    // this.binService.getBin(binActionTypes[this.hallAction], this.orderId, this.promocode).subscribe(bin => {
    this.binService.getBin(binActionTypes[this.hallAction], this.orderId, '').subscribe(bin => {
      if (bin.ticketList && !!this.partHall && isFirstTime) {
        bin.ticketList.forEach(ticket => {
          document.getElementById(ticket.seat.key).style.fill = this.SELECTED_COLOR;
          document.getElementById(ticket.seat.key).setAttribute('seatId', String(ticket.seat.id));
          document.getElementById(ticket.seat.key).setAttribute('ticketId', String(ticket.id));
        });
        if (bin.ticketList[0] && this.partHall?.multiHall) {
          this.displayCurrentSector(document.getElementById(bin.ticketList[0].seat.key).parentElement.parentElement.id);
        }
      }
      this.bin = bin;
      this.loaderService.endLoader();
      // this.tickets = this.tickets.filter(ticket => !this.bin.ticketList.map(el => el.id).includes(ticket.id));
    });
  }

  getVendorList(): void {
    this.vendorService.getShortDtoVendorList(200).subscribe(res => {
      this.vendorList = res.content;
      if (this.vendorId) {
        this.selectedVendor = this.vendorList.find(el => el.id === this.vendorId);
        setTimeout(() => {
          this.selectVendorForm.setValue(this.vendorId);
        }, 1);
      }
    });
  }

  destroySockets() {
    this.socketSubscriptions.forEach(el => (el ? el.unsubscribe() : ''));
    this.webSocket.disconnect();
  }

  promoCodeCheck(promocode: string) {
    this.promocode = promocode;

    this.bookingService.usePromoCode(this.promocode, this.orderId, this.isActivePromoCode).subscribe(
      res => {
        if (res.response && res.response !== 'false') {
          alert(`Промокод ${this.isActivePromoCode ? 'де' : ''}активовано`);
          this.isActivePromoCode = !this.isActivePromoCode;
          this.loadBin();
        } else {
          alert('Промокод не може бути застосований');
        }
      },
      err => {
        alert('Промокод не валідний');
      },
    );
  }

  saveBin(): void {
    this.binService
      .finishBin(
        binActionTypes[this.hallAction],
        +this.orderId,
        this.hallAction === 'invitations'
          ? {
              typeOfPayment: 'NONE',
              comment: this.invitationComment,
              order: this.orderId,
              promoCode: null,
              useBonuse: false,
              user: {
                firstName: this.tempUser.firstName,
                lastName: this.tempUser.lastName,
                mail: this.tempUser.mail,
                phone: '38 (000)-00-00-000',
              },
            }
          : null,
        this.hallAction === 'block' ? this.blockRecipient : null,
      )
      .subscribe(res => this.navigateAway(res));
  }

  selectVendor(id: number): void {
    this.isVendorModalVisible = false;
    this.isVendorContentLoading = false;
    this.vendorId = id;

    this.router
      .navigate([this.router.url.split('?')[0]], {
        queryParams: { vendor: this.vendorId },
        queryParamsHandling: 'merge',
      })
      .then(() => {
        this.selectVendorForm.setValue(this.activateRoute.snapshot.queryParams.vendor);
        this.verifyBinVendor(+this.activateRoute.snapshot.queryParams.vendor, this.activateRoute.snapshot.queryParams.bin);
      });
  }

  selectSeats(event: IOnSelectTicketEvent): void {
    if (this.isChangeStatusMode) {
      this.selectSeatsSecondBin(event);
      return;
    }
    this.createBin().subscribe(res => {
      this.orderId = res;
      let isLocketTiket = false;
      if (event.ticketsIds) {
        isLocketTiket = this.bin ? !!this.bin.ticketList.find(el => el.id === event.ticketsIds[0]) : false;
      }

      if (event.fanzoneKey) {
        event.ticketsIds = this.bin.ticketList.filter(el => el.seat.key === event.fanzoneKey).map(el => el.id);
      } else if (event.ticketGroupId) {
        event.ticketsIds = [this.tickets.filter(el => el.ticketGroupWithOutSachem.id === event.ticketGroupId)[0].id];
      }
      this.loaderService.startLoader();
      this.binService[isLocketTiket ? 'unlockTicket' : 'lockTicket'](binActionTypes[this.hallAction], +res, this.eventId, event.ticketsIds)
        // this.vendorService.selectTickets(+res, event.ticketsIds, !isLocketTiket)
        .subscribe(() => {
          if (!this.activateRoute.snapshot.queryParams.bin) {
            this.router
              .navigate([this.router.url.split('?')[0]], {
                queryParams: { bin: this.orderId },
                queryParamsHandling: 'merge',
              })
              .then(() => {
                this.loadBin();
              });
          } else {
            this.loadBin();
          }
        });

      this.setBinInfo(this.orderId, this.vendorId);
    });
  }

  onVendorSelectionCancel(): void {
    this.isVendorModalVisible = false;
    this.router.navigate(['/']);
  }

  navigateAway(res?: IBin) {
    if (res) {
      this.bin = res;
    }
    if (this.secondOrderId && this.secondBin) {
      this.navigateAwaySecondBin('block');
      return;
    }
    if (this.bin.binStatus === 'BOOKED') {
      this.loaderService.startLoader();
      this.binService
        .unlockTicket(
          binActionTypes[this.hallAction],
          +this.orderId,
          this.eventId,
          this.bin.ticketList.map(el => el.id),
        )
        .subscribe(() => {
          this.router.navigateByUrl(this.hallAction === 'order' ? 'agents' : this.hallAction + '/' + this.eventId + '/list');
          this.loaderService.endLoader();
        });
    } else {
      this.router.navigateByUrl(this.hallAction === 'order' ? 'agents' : this.hallAction + '/' + this.eventId + '/list');
    }
  }

  removeTicketFromBin(ticket: IBinTicket) {
    this.binService.unlockTicket(binActionTypes[this.hallAction], +this.orderId, this.eventId, [ticket.id]).subscribe(() => {
      this.loadBin();
    });
  }

  selectInvitedUser(event) {
    this.validatorService
      .findByEmail(event.target.value)

      .subscribe(
        invitedUser => {
          this.invitedUser = invitedUser;
          this.tempUser.lastName = invitedUser.lastName;
          this.tempUser.firstName = invitedUser.firstName;
        },
        error => {
          if (this.invitedUser) {
            this.tempUser.lastName = '';
            this.tempUser.firstName = '';
          }
          this.invitedUser = null;
          console.error(error);
        },
      );
  }

  findUserByEmail(event): void {
    this.validatorService.findAllByEmail(event.target.value, 0, 20).subscribe(
      invitedUsers => (this.invitedUsers = invitedUsers),
      error => console.error(error),
    );
  }

  ngOnDestroy(): void {
    this.destroySockets();
    this.subscriptions.forEach(el => (el ? el.unsubscribe() : ''));
  }
}
